<template>
  <div>
    <ColaboracoesImporta />
  </div>
</template>

<script>
import ColaboracoesImporta from "@/components/colaboracoes/ColaboracoesImporta.vue";

export default {
  components: {
    ColaboracoesImporta
  }
};
</script>
